<template>
  <div class="term-list-view">
    <app-header icon="heartbeat" title="Termos"></app-header>
    <section>
      <div class="card">
        <div class="card-header">
          <div class="card-header-content">
            <div class="row">
              <div class="col-md-4">
                <b-field label="Filtrar por nome do termo">
                  <b-input
                    v-model="searchName"
                    placeholder="Buscar"
                    type="text"
                    class="m-1"
                  ></b-input>
                </b-field>
              </div>

              <div class="col-md-3">
                <b-field label="Filtrar por tipo de Usuário">
                  <b-select
                    v-model="searchUserType"
                    placeholder="Selecionar"
                    expanded
                    class="m-1"
                  >
                    <option
                      v-for="userType in userTypes"
                      :key="userType.id"
                      :value="userType.id"
                    >
                      {{ userType.name }}
                    </option>
                    <option :key="null" :value="null">Sem tipo definido</option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
          <div class="card-header-buttons">
            <router-link
              :to="{
                name: 'terms.save',
              }"
            >
              <b-button type="is-primary" icon-right="plus">Novo</b-button>
            </router-link>
          </div>
        </div>
        <div class="card-content">
          <section>
            <div class="card">
              <div class="card-content">
                <b-table
                  @page-change="onPageChange"
                  :data="data"
                  :total="total"
                  :per-page="20"
                  :loading="isLoading"
                  paginated
                  backend-pagination
                  aria-next-label="Próxima Página"
                  aria-previous-label="Página Anterior"
                  aria-page-label="Página"
                  aria-current-label="Página Atual"
                >
                  <b-table-column v-slot="props" field="name" label="Nome">
                    {{ props.row.name }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    field="term"
                    label="Tipo do termo"
                  >
                    {{
                      userTypes.find(
                        (element) => element.id == props.row.user_type
                      ).name
                    }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    field="created_at"
                    label="Cadastrado em"
                  >
                    {{ props.row.created_at | toDate }}
                  </b-table-column>
                  <b-table-column
                    v-slot="props"
                    label="Opções"
                    cell-class="width-100"
                  >
                    <router-link
                      :to="{
                        name: 'terms.save',
                        params: { id: props.row.id },
                      }"
                    >
                      <b-tooltip label="Editar" class="m-1">
                        <b-button
                          type="is-secondary"
                          size="is-small"
                          icon-right="edit"
                        />
                      </b-tooltip>
                    </router-link>

                    <b-tooltip label="Deletar" class="m-1">
                      <b-button
                        @click="onClickDelete(props.row.id)"
                        type="is-danger"
                        size="is-small"
                        icon-right="trash"
                      />
                    </b-tooltip>
                  </b-table-column>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey has-text-centered">
                        <p>
                          <b-icon icon="frown" size="is-large"></b-icon>
                        </p>
                        <p>Nenhum registro encontrado.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TermsService from '@/services/terms.service';
import debounce from 'lodash/debounce';

import * as moment from 'moment';

export default {
  data: () => ({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    terms: [],
    userTypes: [],
    searchName: '',
    searchTerm: '',
    searchUserType: null,
  }),
  watch: {
    searchUserType() {
      this.page = 1;
      this.load();
    },
    searchName: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
  },
  filters: {
    toDate(value) {
      if (value) {
        return moment(new Date(value)).format('DD/MM/YYYY');
      }
    },
  },
  methods: {
    load() {
      this.userTypes = [
        { id: 1, name: 'Médico' },
        { id: 2, name: 'Paciente' },
        { id: 3, name: 'Secretária' },
      ];

      this.isLoading = true;

      TermsService.get({
        page: this.page,
        name: this.searchName,
        user_type: this.searchUserType != null ? this.searchUserType : '',
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.load();
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Termo',
        message:
          'Tem certeza que deseja <b>excluir</b> este termo? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Termo',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      TermsService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Termo excluído com sucesso.');
          this.load();
        })
        .catch(({ response }) => {
          if (response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir o Termo.',
              'error',
              4000
            );
          }
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style lang="scss"></style>
